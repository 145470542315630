<template>
	<Form
		v-slot="{ handleSubmit, meta }"
		class="flex h-full gap-4 rounded-md bg-white p-4 text-gray-600"
		tag="section"
		@submit="onMove"
	>
		<Field
			v-if="movableDomains"
			v-slot="{ field }"
			rules="required"
			class="h-full basis-1 sm:basis-7/12"
			name="Selected Domain"
			type="object"
		>
			<RadioGroup
				id="selectedDomain"
				v-model="selectedDomain"
				:options="movableDomains"
				option-key="name"
				class="h-full overflow-auto pl-1"
				legend="Select an active domain to move"
				v-bind="field"
			>
				<template #label="{ name, can_move }">
					<span> {{ name }} </span>
					<span v-if="can_move"> (movable)</span>
					<span v-else> (not movable, owned by someone outside mirus) </span>
				</template>
			</RadioGroup>
		</Field>

		<ProgressBar v-else class="basis-1 sm:basis-7/12" />

		<div class="flex flex-col gap-1 pt-4">
			<Field v-slot="{ field, errors }" :rules="aliasLength" name="Selected Alias">
				<TextFieldInput
					v-model="selectedAlias"
					v-bind="field"
					light
					:disabled="status === 'moving'"
					label="Enter a valid Agent Alias"
					placeholder="alias"
				>
					<template v-if="errors.length > 0" #message> {{ errors[0] }} </template>
				</TextFieldInput>
			</Field>

			<Field
				v-slot="{ handleChange, handleBlur, errors }"
				name="Domain type"
				:rules="domainTypeChosen"
			>
				<BaseSelect
					id="domain_type_for_moved_domain"
					v-model="selectedProductType"
					outlined
					show-label
					:disabled="status === 'moving'"
					:error-messages="errors"
					label="Target Agents Domain Type"
					class="mt-4"
					padding="8"
					:items="domainTypeOptions"
					@update:model-value="handleChange"
					@blur="handleBlur"
				>
					<template v-if="errors.length > 0" #message> {{ errors[0] }} </template>
				</BaseSelect>
			</Field>

			<BaseToggle
				v-model="shouldRemoveRedirects"
				class="mt-4"
				visible-label="Remove all existing email redirects?"
			/>
			<BaseButton :disabled="!meta.dirty || !meta.valid" color="primary" type="submit">
				{{ status === 'moving' ? '...Moving' : 'Move' }}
			</BaseButton>
		</div>
	</Form>

	<BaseAlert v-if="responseErrors" class="mt-4" type="error">
		<ul>
			<li v-for="(errorValue, errorKey) in responseErrors" :key="errorKey">
				<strong>{{ errorKey }}: </strong>
				<span>{{ errorValue }}</span>
			</li>
		</ul>
	</BaseAlert>
	<BaseAlert v-if="status === 'done'" type="success" class="mt-4">
		Domain was successfully moved!
	</BaseAlert>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { Form, Field } from 'vee-validate';

import { storeToRefs } from 'pinia';

import useDomainsStore from '@/stores/agent/agent-domains';
import useAlertStore from '@/stores/alerts';

import ProgressBar from '@/components/ui/ProgressBar.vue';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import BaseToggle from '@/components/ui/BaseToggle.vue';
import RadioGroup from '@/components/ui/RadioGroup.vue';

const domainStore = useDomainsStore();
const alertStore = useAlertStore();

const domainTypeOptions = [
	{ value: 'sf_microsite', text: 'Secure Microsite Proxy' },
	{ value: 'm1', text: 'M1' },
	{ value: 'm2', text: 'M2' },
	{ value: 'parked', text: 'Secure Parked' },
];

function aliasLength(value) {
	if (value === undefined) {
		return true;
	} else if (value.length === 4) {
		return true;
	} else if (value.length === 6) {
		return true;
	}
	return 'Alias should be 4 or 6 characters';
}

function domainTypeChosen(value) {
	if (!domainTypeOptions.map(option => option.value).includes(value)) {
		return 'Domain Type must be chosen';
	}
	return true;
}

const status = ref('loading');

const responseErrors = ref(null);

const shouldRemoveRedirects = ref(true);
const selectedAlias = ref('');
const selectedDomain = ref(null);
const selectedProductType = ref(null);

const { movableDomains } = storeToRefs(domainStore);

async function onMove() {
	status.value = 'moving';
	try {
		const errors = await domainStore.moveDomain({
			target_alias: selectedAlias.value,
			target_product_type: selectedProductType.value,
			remove_redirects: shouldRemoveRedirects.value,
			domain_name: selectedDomain.value,
		});
		status.value = 'done';

		if (errors) {
			responseErrors.value = errors;
		}
	} catch (error) {
		status.value = null;
		alertStore.addAlert({ error, message: error.message });
	}
}
onMounted(async () => {
	await domainStore.getMovableDomains();
	status.value = null;
	responseErrors.value = null;
});
</script>

<style scoped></style>
