<template>
	<div
		ref="app"
		:class="[
			{
				'sticky top-0 !h-screen overflow-hidden': drawerIsOpen && isSmallScreen,
				[drawerIsOpen
					? 'grid-cols-[min-content_minmax(0,_1fr)]'
					: 'grid-cols-[minmax(0,_1fr)]']: true,
				dark: isDark,
			},
			isDark ? 'text-gray-300' : 'text-gray-900',
			warnAboutProd
				? isDark
					? 'bg-red-900'
					: 'bg-red-200'
				: isDark
					? 'bg-gray-600'
					: 'bg-white',
		]"
		class="appWrapper relative grid h-full w-screen dark:text-gray-100"
		data-app
	>
		<TOSUpdate v-if="user?.tos_accepted === false" />
		<TheHeader
			v-model:mirus-controls="mirusControls"
			:class="{ 'sm:col-span-2': drawerIsOpen }"
			class="col-span-1 col-start-1 row-start-1"
		>
			<template #skipLink>
				<div
					class="absolute left-0 top-1 grid w-full -translate-y-24 place-content-center overflow-hidden !transition-all focus-within:translate-y-0"
				>
					<a
						ref="skipLink"
						class="m-1 border-2 border-blue-500 bg-white p-5 !text-blue-600 underline"
						href="#main"
					>
						Skip to main content
					</a>
				</div>
			</template>
		</TheHeader>

		<TheSideNav
			v-if="showNavigation && drawerIsOpen"
			class="fixed top-20 z-40 col-start-1 row-span-6 row-start-2 w-full sm:sticky sm:w-auto print:hidden"
		>
			<template #skipLink>
				<a
					ref="navSkipLink"
					class="absolute z-10 w-full -translate-y-24 bg-white px-2 py-5 text-center text-blue-600 !transition-all focus:translate-y-0"
					href="#main"
					@click="navigationStore.hideDrawerOnSmallScreens"
					@keydown.esc="drawerIsOpen = false"
					@keydown.enter="navigationStore.hideDrawerOnSmallScreens"
				>
					Skip to main content
				</a>
			</template>
		</TheSideNav>

		<div
			:class="{ 'sm:col-start-2': drawerIsOpen }"
			class="top-20 z-40 col-start-1 row-start-2 sm:sticky"
		>
			<VueAnnouncer class="sr-only" />
			<AlertList />
			<MirusControls v-if="mirusControls" />
		</div>
		<DomainProductTypeChangeDialog />
		<SaveDialog />
		<main id="main" class="z-0 col-start-2 contents">
			<router-view
				v-if="user || ['logout', 'login'].includes($route.name)"
				ref="routerView"
				:class="{
					'sm:col-start-2': drawerIsOpen,
				}"
				class="col-start-1 contents"
			/>
		</main>
		<TheFooter
			:class="{ 'sm:col-start-2': drawerIsOpen }"
			class="bottom-0 col-start-1 row-start-6 print:col-start-1"
		/>
	</div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { useWindowSize, useDark } from '@vueuse/core';

import useUserStore from '@/stores/user';
import useSupportStore from '@/stores/support';
import useConfigStore from '@/stores/site/config';
import useNotificationStore from '@/stores/site/notifications';
import useNavigationStore from '@/stores/navigation';

import SaveDialog from '@/components/ui/SaveDialog.vue';

import DomainProductTypeChangeDialog from '@/components/common/DomainProductTypeChangeDialog.vue';

import AlertList from '@/components/common/AlertList';
import TOSUpdate from '@/components/common/TOSUpdate';

import TheFooter from '@/TheFooter';
import TheSideNav from '@/TheSideNav';
import TheHeader from '@/TheHeader';

import MirusControls from '@/components/MirusControls/MirusControls';

const isDark = useDark({ initialValue: 'light', attribute: 'color-scheme' });
const { width } = useWindowSize();
const route = useRoute();
const router = useRouter();

const configStore = useConfigStore();
const notificationStore = useNotificationStore();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const navigationStore = useNavigationStore();
const { showNavigation, drawerIsOpen, skipLink, navSkipLink } = storeToRefs(navigationStore);

const supportStore = useSupportStore();
const { warnAboutProd } = storeToRefs(supportStore);

const mirusControls = computed({
	get: () => Boolean(route?.query?.admin),
	set(value) {
		const { admin, ...rest } = route.query;
		const query = { ...rest };
		if (value) {
			query.admin = 'search';
		}
		router.replace({ query });
	},
});
const isSmallScreen = computed(() => width.value < 640);

onMounted(async () => {
	await userStore.getUserData();

	// always check for site config & notifications
	if (userStore?.user?.authenticated && notificationStore.notifications === null) {
		await notificationStore.getSiteNotifications();
	}
	if (!configStore.config) {
		await configStore.getSiteConfig();
	}
});
</script>
<style>
.skipLinkTarget {
	@apply scroll-mt-52 bg-white py-1 text-center text-lg !text-orange-700;
	@apply focus:border-2 focus:border-orange-300 focus:outline-none focus:ring-orange-500;
}
</style>
<style lang="scss">
.bg-checkerboard {
	background-image: url('./assets/images/UI/Transparency500.png');
	@apply bg-cover;
}

p:not(:first-child) {
	@apply mt-2;
}

kbd {
	@apply rounded bg-gray-900 px-1 pb-0.5 pt-1 font-mono font-bold text-white shadow-none;
}
.container {
	@apply p-2 lg:p-4 2xl:p-8;
}

a[href] {
	@apply cursor-pointer font-semibold transition-colors;

	@apply inline-block underline decoration-solid decoration-2 underline-offset-2;

	&:not(.inheritColor) {
		&,
		&.infoBlue {
			@apply text-blue-700 hover:text-gray-900 dark:text-blue-200 dark:hover:text-gray-100;
		}
		&.warnYellow {
			@apply text-yellow-700 hover:text-gray-900 dark:text-yellow-100 dark:hover:text-gray-100;
		}
		&.errorRed {
			@apply text-red-700 hover:text-gray-900 dark:text-red-100 dark:hover:text-gray-200;
		}
		&.successGreen {
			@apply text-green-800 hover:text-gray-900 dark:text-green-400 dark:hover:text-gray-100;
		}
		&.mirusWhite {
			@apply text-white hover:text-gray-400 dark:text-gray-300 dark:hover:text-white;
		}
		&.mirusGray {
			@apply text-gray-900 hover:text-orange-900 dark:text-gray-200 dark:hover:text-orange-300;
		}
		&.mirusOrange {
			@apply text-orange-800 hover:text-gray-700 dark:text-orange-300 dark:hover:text-gray-200;
		}
	}
}
</style>

<style lang="scss" scoped>
@import 'styles/variables';
@import 'styles/main';

// increased specificity to override vuetify

div.appWrapper {
	/*
    GRID ROWS:
      1. header
      2. Skip links, alerts, & mirus controls
      3-4. main content, made to accommodate agent & editor views:
        3. Agent view toolbar & Edit/Help/Live buttons
        4. notifications & live view controls
        5. view/editor content
      6. footer
    */
	grid-template-rows: repeat(2, min-content) max-content min-content 1fr min-content;
	& .link {
		text-decoration: none;
		color: white;
		text-transform: lowercase;
		font-weight: 500;
		font-size: 1.25rem;
		opacity: 1;
		transition: opacity 0.3s ease;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			height: 1px;
			width: 0;
			transition: width 0.2s ease;
			background-color: white;
		}

		&:hover {
			opacity: 1;
			&:after {
				width: 100%;
			}
		}
		.titleSecond {
			color: black;
			text-transform: uppercase;
			font-size: 0.95rem;
			line-height: 1;
		}
	}

	.headerLabel {
		font-size: 2rem;
		color: white;
		white-space: nowrap;
	}

	.adminLogo {
		cursor: pointer;
		margin-left: 15px;
		opacity: 0.75;
		transition:
			transform 0.3s ease-in-out,
			opacity 0.3s ease;
		&:hover {
			opacity: 1;
		}
	}

	.footerLine {
		height: 2px;
		border: none;
	}
}
</style>
