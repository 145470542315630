<template>
	<fieldset
		:class="[{ dark, 'w-full': fullWidth, disabled }, pill ? 'rounded-full' : 'rounded']"
		class="relative"
		v-bind="{ disabled }"
	>
		<div :class="{ pill, outlined, transparent }" class="selectWrapper">
			<select
				:id="id.replaceAll(' ', '_')"
				ref="selectElement"
				v-model="localValue"
				:name="id.replaceAll(' ', '_')"
				class="selectElement peer bg-none"
				@blur="$emit('blur')"
			>
				<option
					v-if="showDefaultOption"
					:selected="!modelValue"
					disabled
					value=""
					v-text="label"
				/>
				<option
					v-for="item in localItems"
					:key="JSON.stringify(item)"
					:selected="selectedItem?.[itemValueKey] === item?.[itemValueKey]"
					:class="{ capitalize }"
					class="text-black"
					:disabled="item.disabled"
					:value="item[itemValueKey]"
				>
					{{ item?.text ?? item?.[itemValueKey] ?? item }}
				</option>
				<slot name="append-item" />
			</select>
			<span class="visibleText">
				<span class="ml-3 truncate">
					{{ selectedItemText }}
				</span>
				<svg
					class="pointer-events-none ml-auto mr-3 shrink-0 grow-0 stroke-2 opacity-80"
					fill="none"
					stroke="currentColor"
					stroke-linecap="round"
					viewBox="0 0 12 12"
				>
					<path d="M1,5 l5,5 l5,-5" />
				</svg>
			</span>
			<label
				:for="id.replaceAll(' ', '_')"
				:class="[
					showLabel ? '' : 'sr-only',
					disabled ? 'text-gray-500' : 'text-gray-600',
					!selectedItem ? 'left-3 top-1.5 text-base' : 'text-xs',
				]"
				class="pointer-events-none absolute -top-4 left-0 whitespace-nowrap transition-all peer-focus:-top-4 peer-focus:left-0 peer-focus:text-xs"
			>
				{{ label }}
			</label>
		</div>

		<p
			v-if="$slots.message"
			class="font-med my-1 flex items-center px-2 text-xs text-red-600"
			:class="messageAbsolute ? 'absolute' : ''"
		>
			<slot name="message" />
		</p>
	</fieldset>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const emit = defineEmits(['update:modelValue', 'blur']);
const props = defineProps({
	modelValue: { type: [Object, Number, Boolean, String], default: null },

	items: { type: [Array], default: () => [], required: true },

	id: { type: String, required: true },
	label: { type: String, required: true },

	disabled: { type: Boolean, default: false },

	itemTextKey: { type: String, default: 'text' },
	itemValueKey: { type: String, default: 'value' },

	capitalize: { type: Boolean, default: false },
	dark: { type: Boolean, default: false },
	pill: { type: Boolean, default: false },
	transparent: { type: Boolean, default: false },
	showLabel: { type: Boolean, default: false },
	showDefaultOption: { type: Boolean, default: true },
	outlined: { type: Boolean, default: false },
	messageAbsolute: { type: Boolean, default: false },
	fullWidth: { type: Boolean, default: true },

	valueType: {
		type: String,
		required: false,
		default: 'string',
		validator: value => {
			const validTypes = ['object', 'string', 'number', 'boolean'];
			if (value && !validTypes.includes(value)) {
				console.error(`valueType must be one of:`);
				console.warn(validTypes);
				return false;
			} else {
				return true;
			}
		},
	},
	rules: {
		type: [String, Object],
		default: null,
	},
});

const selectElement = ref(null);

function parseItem(item) {
	let finalItem;
	switch (props.valueType) {
		case 'array':
			finalItem = {
				[props.itemTextKey]: item.join(', '),
				[props.itemValueKey]: item.join(','),
				valueToEmit: item,
			};
			break;
		case 'object':
			// todo: figure out if some of these are edge cases that no longer apply
			if (['string', 'number', 'boolean'].includes(typeof item)) {
				const targetItem = props.items.find(propItem => {
					let itemMatchesProp;
					switch (props.valueType) {
						case 'array':
							itemMatchesProp =
								propItem[props.itemValueKey] === props.modelValue.join(',');
							break;
						case 'object':
							itemMatchesProp =
								propItem[props.itemValueKey] === item ||
								propItem[props.itemValueKey] === props.modelValue;
							break;
						case 'string':
						case 'number':
						case 'boolean':
						default:
							itemMatchesProp = item.valueToEmit === props.modelValue;
					}
					return itemMatchesProp;
				});

				finalItem = {
					[props.itemTextKey]: targetItem?.[props.itemTextKey] ?? JSON.stringify(item),
					[props.itemValueKey]: targetItem?.[props.itemValueKey] ?? JSON.stringify(item),
					valueToEmit: targetItem,
				};
				break;
			}
			finalItem = {
				[props.itemTextKey]:
					item?.[props.itemTextKey] ?? item?.[props.itemValueKey] ?? JSON.stringify(item),
				[props.itemValueKey]: item?.[props.itemValueKey] ?? JSON.stringify(item),
				valueToEmit: item,
			};

			break;
		case 'string':
		case 'number':
		case 'boolean':
		default:
			switch (typeof item) {
				case 'object':
					finalItem = {
						...item,
						[props.itemTextKey]:
							item?.[props.itemTextKey] ??
							item?.[props.itemValueKey] ??
							JSON.stringify(item),
						[props.itemValueKey]: item?.[props.itemValueKey] ?? JSON.stringify(item),
						valueToEmit: item?.[props.itemValueKey] ?? JSON.stringify(item),
					};
					break;
				case 'string':
				case 'number':
				case 'boolean':
				default:
					finalItem = {
						[props.itemTextKey]: item,
						[props.itemValueKey]: item,
						valueToEmit: item,
					};
			}
	}

	return finalItem;
}

const localItems = computed(() => {
	// standardize item list to match template needs
	return props.items?.length > 0 ? props.items.map(parseItem) : props.items;
});
const selectedItem = computed(() => {
	return localItems.value?.find(item => item[props.itemValueKey] === localValue.value);
});

const selectedItemText = computed(() => {
	if (!selectedItem.value) {
		return '';
	}
	return selectedItem.value[props.itemTextKey];
});

const localValue = ref(parseItem(props.modelValue)[props.itemValueKey]);
function focusSelectElement() {
	selectElement.value.focus();
}

watch(
	selectedItem,
	(newValue, oldValue) => {
		const diffValues = oldValue?.valueToEmit !== newValue?.valueToEmit;
		if (diffValues) {
			emit('update:modelValue', newValue?.valueToEmit);
		}
	},
	{ deep: true, immediate: false }
);

watch(
	() => [props.items, props.modelValue],
	() => {
		const newLocalValue = parseItem(props.modelValue)[props.itemValueKey];
		if (newLocalValue && newLocalValue !== localValue.value) {
			localValue.value = newLocalValue;
		}
	},
	{ deep: true, immediate: false }
);

defineExpose({
	localItems,
	focus: focusSelectElement,
	parseItem,
});
</script>

<style soped lang="scss">
.selectElement {
	@apply border-0 opacity-0;
	@apply appearance-none placeholder-transparent sm:text-sm;
}
.visibleText {
	@apply pointer-events-none flex items-center gap-x-1.5 truncate text-left font-bold;
}
.selectElement,
.visibleText {
	@apply col-start-1 row-start-1 cursor-pointer;
}
.selectWrapper {
	@apply grid w-full grid-cols-1 grid-rows-1;
	@apply relative items-center gap-x-1 rounded;
	@apply ring-0 ring-transparent ring-offset-transparent dark:ring-gray-600;
	@apply disabled:cursor-not-allowed disabled:text-gray-300;

	@apply focus-within:outline-none focus-within:ring-2 focus-within:ring-orange dark:focus-within:ring-orange-400;
	&.outlined {
		@apply border border-solid border-gray-500 disabled:border-gray-300;
	}
	&.pill {
		@apply text-ellipsis rounded-full;
	}
	svg {
		pointer-events: none;
		width: clamp(10px, 1em, 0.8ch);
		height: clamp(10px, 1em, 0.8ch);
	}

	.disabled & {
		@apply pointer-events-none cursor-not-allowed;
	}
}
</style>
